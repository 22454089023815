.Toolbar {
    height: 40px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 10px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar nav {
    height: 100%;
    padding: 0px 10px;
}

@media (max-width: 499px) {
    .Toolbar {
        display: none;
    }
}

.LeftNavItems {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;
}

.RightNavItems {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;
}

.Logout {
    height: 80%;
    width: 100%;
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
}

@media (min-width: 500px) {
    .Logout {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
}

.LinkIcon {
    display: inline-flex;
}

.LinkText {
    padding: 5px;
}