.Auth {
    margin: 7% auto;
    width: 80%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 30px;
    box-sizing: border-box;
    background-color: white;
}

@media (min-width: 600px) {
    .Auth {
        width: 400px;
    }
}

.LogoImage {
    width: 150px;
    height: 55px;
    margin: auto;
    padding: 0px 0px 5px 0px;
    box-sizing: border-box;
}

.InputIconColor {
    color: #495057
}

.InputPadding {
    padding: 15px 5px 5px 5px
}

.Button {
    padding: 25px 5px 5px 5px
}

.LineSeparator {
    margin: auto;
    background-color: grey;
    width: 118%;
    left: -9%;
    height: 3px;
    position: relative;
    display: inline-flex;
}