.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
}

.AccountMenu {
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    cursor: pointer;
}

@media (min-width: 500px) {
    .AccountMenu {
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
}