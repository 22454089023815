.Footer {
    height: 30px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 0px 0px 10px;
    box-sizing: border-box;
    z-index: 90;
}

@media (max-width: 499px) {
    .Footer {
        justify-content: center;
    }
}